import { HeaderExtraSmall, HeaderMedium } from '@animoto/components';
import { trackFeatureUsage } from '@animoto/utils/analytics';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import gettingStarted from './assets/getting-started.png';
import whatsNewWithAnimoto from './assets/whats-new-with-animoto.png';
import workLife from './assets/work-life.png';
import {
  blog,
  blogImage,
  blogsWrapper,
  blogTitle,
  blogWrapper,
  header,
  headerWrap,
  wrap
} from './BlogPosts.module.css';

const blogs = [
  {
    image : gettingStarted,
    style : 'getting-started',
    title : 'Complete guide to getting started with Animoto',
    url   : '/blog/guides/getting-started-animoto'
  },
  {
    image : workLife,
    style : 'work-and-life',
    title : 'Our most popular video templates for work and life',
    url   : '/blog/video-ideas/most-popular-video-templates-work-life'
  },
  {
    image : whatsNewWithAnimoto,
    style : 'whats-new-with-animoto',
    title : 'What’s new with Animoto',
    url   : '/blog/news/whats-new-with-animoto'
  }
];
export default function BlogPosts() {
  // TODO: refactor to not use useMediaQuery
  const isMiddleSize = useMediaQuery({ minWidth : 480, maxWidth : 992 });

  const onClickBlog = (url) => {
    trackFeatureUsage({
      scope   : 'visitor-play-page',
      feature : 'Blog-Posts',
      action  : `click:${url}`
    });
  };

  const generateBlogs = () => blogs.map(({
    url,
    style,
    title,
    image
  }) => (
    <div key={style} className={blogWrapper}>
      <a className={blog} href={url} onClick={() => onClickBlog(url)} rel="noopener noreferrer" target="_blank">
        <img
          alt={style}
          className={blogImage}
          height={isMiddleSize ? '99' : '72'}
          src={image}
          value={url}
          width={isMiddleSize ? '148' : '108'}
        />
      </a>
      <a className={blog} href={url} onClick={() => onClickBlog(url)} rel="noopener noreferrer" target="_blank">
        <HeaderExtraSmall
          className={blogTitle}
          value={url}
        >{title}
        </HeaderExtraSmall>
      </a>
    </div>
  ));

  return (
    <div className={wrap}>
      <div className={headerWrap}>
        <HeaderMedium className={header}>Featured Blog Posts</HeaderMedium>
      </div>
      <div className={blogsWrapper}>
        {generateBlogs()}
      </div>
    </div>
  );
}
